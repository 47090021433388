exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-csr-js": () => import("./../../../src/pages/about-us/CSR.js" /* webpackChunkName: "component---src-pages-about-us-csr-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-coreteam-js": () => import("./../../../src/pages/coreteam.js" /* webpackChunkName: "component---src-pages-coreteam-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-powertec-engineering-js": () => import("./../../../src/pages/projects/powertec-Engineering.js" /* webpackChunkName: "component---src-pages-projects-powertec-engineering-js" */),
  "component---src-pages-projects-powertec-infrastructure-js": () => import("./../../../src/pages/projects/powertec-infrastructure.js" /* webpackChunkName: "component---src-pages-projects-powertec-infrastructure-js" */),
  "component---src-pages-service-infrastructure-js": () => import("./../../../src/pages/service/infrastructure.js" /* webpackChunkName: "component---src-pages-service-infrastructure-js" */),
  "component---src-pages-service-powertec-js": () => import("./../../../src/pages/service/powertec.js" /* webpackChunkName: "component---src-pages-service-powertec-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

